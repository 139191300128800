import Vue from "vue";
import VueRouter, { RouteConfig } from "vue-router";
import Home from "@/views/Home.vue";
import Form from "@/views/Form.vue";
import AppNavbar from "@/components/AppNavbar.vue";
import AppFooter from "@/components/AppFooter.vue";
import {
  GetLocale,
  GetStartingLocale,
  GetSupportedLocales,
  SetLocale
} from "@/utility/LanguageHelper";
import user from "@/store/modules/UserModule";
import admin from "@/store/modules/AdminModule";
import { useGtm } from "vue-gtm";

Vue.use(VueRouter);

const routes: Array<RouteConfig> = [
  {
    path: "/",
    name: "home",
    components: {
      header: AppNavbar,
      default: Home,
      footer: AppFooter
    }
  },
  {
    path: "/login",
    name: "login",
    components: {
      header: AppNavbar,
      default: () => import("@/views/Login.vue"),
      footer: AppFooter
    }
  },
  {
    path: "/register",
    name: "register",
    components: {
      header: AppNavbar,
      default: () => import("@/views/Register.vue"),
      footer: AppFooter
    }
  },
  {
    path: "/price-list",
    name: "price-list",
    components: {
      header: AppNavbar,
      default: () => import("@/views/PriceList.vue"),
      footer: AppFooter
    }
  },
  {
    path: "/questions-and-answers",
    name: "questions-and-answers",
    components: {
      header: AppNavbar,
      default: () => import("@/views/QuestionsAndAnswers.vue"),
      footer: AppFooter
    }
  },
  {
    path: "/questions-and-answers-companies",
    name: "questions-and-answers-companies",
    components: {
      header: AppNavbar,
      default: () => import("@/views/QuestionsAndAnswersCompanies.vue"),
      footer: AppFooter
    }
  },
  {
    path: "/blog/:lang",
    name: "blog",
    components: {
      header: AppNavbar,
      default: () => import("@/views/BlogMain.vue"),
      footer: AppFooter
    },
    beforeEnter(to: any, from: any, next: any) {
      let lang = to.params.lang;
      if (lang == undefined || !GetSupportedLocales().includes(lang)) {
        next({ path: "blog/" + GetStartingLocale() });
      } else {
        SetLocale(lang);
        next();
      }
    }
  },
  {
    path: "/blog/:lang/:blogUrl",
    name: "blog-dynamic",
    components: {
      header: AppNavbar,
      default: () => import("@/views/BlogDynamic.vue"),
      footer: AppFooter
    }
  },
  {
    path: "/about",
    name: "about",
    components: {
      header: AppNavbar,
      default: () => import("@/views/About.vue"),
      footer: AppFooter
    }
  },
  {
    path: "/email-verification",
    name: "email-verification",
    components: {
      default: () => import("@/views/EmailVerification.vue")
    }
  },
  {
    path: "/delete-quotation-request",
    name: "delete-quotation-request",
    components: {
      default: () => import("@/views/DeleteQuotationRequest.vue")
    }
  },
  {
    path: "/forgot-password",
    name: "forgot-password",
    components: {
      default: () => import("@/views/ForgotPassword.vue")
    }
  },
  {
    path: "/reset-password",
    name: "reset-password",
    components: {
      default: () => import("@/views/PasswordReset.vue")
    }
  },
  {
    path: "/dashboard",
    name: "dashboard",
    components: {
      header: () => import("@/components/Dashboard/User/DashboardNavbar.vue"),
      default: () => import("@/views/DashboardBase.vue")
    },
    async beforeEnter(to: any, from: any, next: any) {
      await user
        .tryMaintainSession()
        .then(async () => {})
        .catch(() => {
          user.clear();
        });

      if (!to.meta.allowAnonymous && !user.isLoggedIn) {
        next({
          path: "/login"
        });
      } else {
        if (user.isLoggedIn) {
          SetLocale(user.language);
        }
        next();
      }
    },
    children: [
      {
        path: "quotation-requests",
        name: "dashboard-quotation-requests",
        components: {
          default: () =>
            import("@/components/Dashboard/User/DashboardQuotationRequests.vue")
        }
      },
      {
        path: "profile",
        name: "dashboard-profile",
        components: {
          default: () =>
            import("@/components/Dashboard/User/DashboardProfile.vue")
        }
      },
      {
        path: "subscription",
        name: "dashboard-subscription",
        components: {
          default: () =>
            import("@/components/Dashboard/User/DashboardSubscription.vue")
        }
      }
    ]
  },
  {
    path: "/admin/login",
    name: "admin-login",
    components: {
      default: () => import("@/views/Admin/AdminLogin.vue")
    },
    beforeEnter(to: any, from: any, next: any) {
      const defaultAdminLanguage = "sv";
      if (GetLocale() != defaultAdminLanguage) SetLocale(defaultAdminLanguage);
      next();
    }
  },
  {
    path: "/admin",
    name: "admin",
    components: {
      header: () => import("@/components/Dashboard/Admin/DashboardNavbar.vue"),
      default: () => import("@/views/Admin/Admin.vue")
    },
    async beforeEnter(to: any, from: any, next: any) {
      await admin
        .tryMaintainSession()
        .then(async () => {})
        .catch(() => {});

      const defaultAdminLanguage = "sv";
      if (GetLocale() != defaultAdminLanguage) SetLocale(defaultAdminLanguage);
      if (!to.meta.allowAnonymous && !admin.isLoggedIn) {
        next({
          path: "/admin/login"
        });
      } else {
        next();
      }
    },
    children: [
      {
        path: "dashboard",
        name: "admin-dashboard",
        components: {}
      },
      {
        path: "create-company",
        name: "admin-dashboard-create-company",
        components: {
          default: () =>
            import("@/components/Dashboard/Admin/DashboardCreateCompany.vue")
        }
      },
      {
        path: "invoices",
        name: "admin-dashboard-invoices",
        components: {
          default: () =>
            import("@/components/Dashboard/Admin/DashboardInvoices.vue")
        }
      },
      {
        path: "quotation-requests",
        name: "admin-dashboard-quotation-requests",
        components: {
          default: () =>
            import(
              "@/components/Dashboard/Admin/DashboardQuotationRequests.vue"
            )
        }
      },
      {
        path: "view-company",
        name: "admin-dashboard-view-company",
        components: {
          default: () =>
            import("@/components/Dashboard/Admin/DashboardViewCompany.vue")
        }
      },
      {
        path: "blog-entries",
        name: "admin-dashboard-create-blog-entry",
        components: {
          default: () =>
            import("@/components/Dashboard/Admin/DashboardBlogEntries.vue")
        }
      },
      {
        path: "blog-entries-edit",
        name: "admin-dashboard-edit-blog-entry",
        components: {
          default: () =>
            import("@/components/Dashboard/Admin/DashboardEditBlogEntries.vue")
        }
      },
      {
        path: "discount-codes",
        name: "admin-discount-codes",
        components: {
          default: () =>
            import("@/components/Dashboard/Admin/DashboardDiscountCodes.vue")
        }
      }
    ]
  },
  {
    path: "/:lang",
    components: {
      header: AppNavbar,
      default: Form,
      footer: AppFooter
    },
    beforeEnter(to, from, next) {
      let lang = to.params.lang as string;
      if (!GetSupportedLocales().includes(lang)) {
        next({ path: "/" });
      }

      let parentCategory = to.params.parentCategory as string;
      if (parentCategory == undefined) {
        next({ path: "/" });
      }

      if (GetLocale() !== lang) {
        SetLocale(lang);
      }

      return next();
    },
    children: [
      {
        path: ":parentCategory",
        name: "quotation-request-main-category",
        component: () => import("@/views/BaseForm.vue")
      },
      {
        path: ":parentCategory/:childCategory",
        name: "quotation-request-sub-category",
        component: () => import("@/views/BaseForm.vue")
      }
    ]
  }
];

const router = new VueRouter({
  mode: "history",
  routes
});
router.afterEach(to => {
  useGtm()?.trackView(to.name ? to.name : "", to.path);
  // @ts-ignore
  //window.fbq("trackView", to.name ? to.name : "", to.path);
});

export default router;
