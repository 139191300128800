import { IAccountRegistration } from "@/models/AccountRegistration";
import { IPasswordReset } from "@/models/PasswordReset";

import { HttpClient } from "./HttpClient";
import { AxiosResponse } from "axios";
import { ILoginDetails } from "@/models/LoginDetails";
import { AuthenticationResult } from "@/models/AuthenticationResult";

export class UserService {
  static async Register(
    accountRegistration: IAccountRegistration
  ): Promise<AxiosResponse> {
    return HttpClient.post("/user/register", accountRegistration);
  }

  static async GetQuotationRequests(): Promise<AxiosResponse> {
    return HttpClient.get("/user/quotation-requests");
  }

  static GetQuotationRequest(
    quotationRequestId: number
  ): Promise<AxiosResponse> {
    return HttpClient.get(`/user/quotation-request/${quotationRequestId}`);
  }

  static VerifyEmail(code: string): Promise<AxiosResponse> {
    return HttpClient.post(`/user/verify-email/${code}`);
  }

  static SendVerificationEmail(): Promise<AxiosResponse> {
    return HttpClient.post("/user/send-email-verification");
  }

  static SendPasswordResetEmail(
    email: string,
    language: string
  ): Promise<AxiosResponse> {
    return HttpClient.post(`/user/send-password-reset/${language}/${email}`);
  }

  static ResetPassword(passwordReset: IPasswordReset): Promise<AxiosResponse> {
    return HttpClient.post(`/user/reset-password`, passwordReset);
  }

  static async LoginUser(
    loginDetails: ILoginDetails
  ): Promise<AuthenticationResult> {
    const response = await HttpClient.post("/user/login", loginDetails);
    return response.data as AuthenticationResult;
  }

  static async LogoutUser(): Promise<AxiosResponse> {
    return HttpClient.post("/user/logout");
  }

  static async MaintainSession(): Promise<AuthenticationResult> {
    const response = await HttpClient.post("/user/context");
    return response.data as AuthenticationResult;
  }
}
