import {
  VuexModule,
  Module,
  getModule,
  MutationAction,
  Mutation,
  Action
} from "vuex-module-decorators";
import store from "@/store";
import { IUser } from "@/models/User";
import { ILoginDetails } from "@/models/LoginDetails";
import { UserService } from "@/api/UserServiceApi";
import { AxiosResponse } from "axios";
import { ISubscriptionDetails } from "@/models/SubscriptionDetails";

@Module({
  namespaced: true,
  name: "usersModule",
  store,
  dynamic: true
})
class UsersModule extends VuexModule {
  user: IUser | null = null;

  get isLoggedIn(): boolean {
    return this.user != null && this.user.id > 0;
  }

  get hasVerifiedEmail(): boolean {
    return this.user!.emailVerified;
  }

  get language(): string {
    return this.user!.language;
  }

  get companyName(): string {
    if (this.user == null) {
      return "";
    }

    return this.user.company.companyName;
  }

  get businessCode(): string {
    if (this.user == null) {
      return "";
    }

    return this.user.company.businessCode;
  }

  get contactPerson(): string {
    if (this.user == null) {
      return "";
    }

    return this.user.company.contactPerson;
  }

  get email(): string {
    if (this.user == null) {
      return "";
    }

    return this.user.email;
  }

  get mobilePhone(): string {
    if (this.user == null) {
      return "";
    }

    return this.user.mobilePhone;
  }

  get subscriptionDetails(): ISubscriptionDetails {
    return this.user!.company!.subscriptionDetails;
  }

  @Action
  logout(): Promise<AxiosResponse> {
    return UserService.LogoutUser();
  }

  @Mutation
  clear() {
    this.user = null;
  }

  @MutationAction
  async login(loginDetails: ILoginDetails) {
    const response = await UserService.LoginUser(loginDetails);
    const user = response.user;
    return { user };
  }

  @MutationAction
  async tryMaintainSession() {
    const response = await UserService.MaintainSession();
    const user = response.user;
    return { user };
  }
}

export default getModule(UsersModule);
