
import { Vue, Component, Prop } from "vue-property-decorator";
import { mixin as clickaway } from "vue-clickaway";
import { IDropdownItem } from "./Dropdown.vue";

@Component({ mixins: [clickaway] })
export default class MobileNavbarDropDown extends Vue {
  active: boolean = false;

  @Prop({})
  items!: Array<IDropdownItem>;

  toggle() {
    this.active = !this.active;
  }

  away() {
    this.active = false;
  }

  emitClick() {
    this.$emit("click");
  }
}
