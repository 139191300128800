<template>
  <div id="app">
    <router-view name="header"></router-view>
    <router-view />
    <router-view name="footer"></router-view>
    <footer>
      <cookie-law
        theme="dark-lime"
        :message="$t('COOKIE_NOTIFICATION.TEXT')"
        :buttonText="$t('COOKIE_NOTIFICATION.AGREE_TEXT')"
        buttonClass="pt-2 pb-2 pl-3 pr-3 rounded bg-blue-500"
      ></cookie-law>
    </footer>
  </div>
</template>

<script>
import CookieLaw from "vue-cookie-law";
export default {
  components: { CookieLaw }
};
</script>

<style>
@import url("https://fonts.googleapis.com/css2?family=Poppins");
#app {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Poppins", sans-serif;
}
</style>
