
import { Vue, Component, Prop } from "vue-property-decorator";
import { mixin as clickaway } from "vue-clickaway";

export interface IDropdownItem {
  link: string;
  title: string;
}

@Component({ mixins: [clickaway] })
export default class Dropdown extends Vue {
  active: boolean = false;

  @Prop({ default: "default" })
  title!: string;

  @Prop({})
  items!: Array<IDropdownItem>;

  toggle() {
    this.active = !this.active;
  }

  away() {
    this.active = false;
  }
}
