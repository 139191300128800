
import { Vue, Component, Prop } from "vue-property-decorator";
import { mixin as clickaway } from "vue-clickaway";
import { SetLocale, GetLocale } from "@/utility/LanguageHelper";

@Component({ mixins: [clickaway] })
export default class Dropdown extends Vue {
  @Prop({ default: false })
  mobile!: boolean;

  active: boolean = false;

  supportedLocales: { [key: string]: string } = {
    sv: "Svenska",
    fi: "Suomeksi",
    en: "English"
  };

  toggle() {
    this.active = !this.active;
  }

  away() {
    this.active = false;
  }

  setLocale(locale: string) {
    SetLocale(locale);
    this.$emit("click");
  }

  get locales() {
    let localeCopy: { [key: string]: string } = {};
    for (let key in this.supportedLocales) {
      localeCopy[key] = this.supportedLocales[key];
    }
    delete localeCopy[GetLocale()];
    return localeCopy;
  }
}
