import {
  VuexModule,
  Module,
  getModule,
  MutationAction,
  Mutation,
  Action
} from "vuex-module-decorators";
import store from "@/store";
import { ILoginDetails } from "@/models/LoginDetails";
import { AdminService } from "@/api/AdminServiceApi";
import { AxiosResponse } from "axios";
import { IAdmin } from "@/models/Admin/Admin";

@Module({
  namespaced: true,
  name: "adminsModule",
  store,
  dynamic: true
})
class AdminsModule extends VuexModule {
  admin: IAdmin | null = null;

  get isLoggedIn(): boolean {
    return this.admin != null && this.admin.id > 0;
  }

  @Action
  logout(): Promise<AxiosResponse> {
    return AdminService.Logout();
  }

  @Mutation
  clear() {
    this.admin = null;
  }

  @MutationAction
  async login(loginDetails: ILoginDetails) {
    const response = await AdminService.Login(loginDetails);
    const admin = response.admin;
    return { admin };
  }

  @MutationAction
  async tryMaintainSession() {
    const response = await AdminService.MaintainSession();
    const admin = response.admin;
    return { admin };
  }
}

export default getModule(AdminsModule);
