
import { Component, Vue } from "vue-property-decorator";
import { GetLocale } from "@/utility/LanguageHelper";

@Component({
  metaInfo() {
    return {
      title: "Jämför priser på tjänsteföretag - Gratis - Dealfinder",
      meta: [
        {
          name: "description",
          content:
            "Jämför priser på ditt jobb - alltid en kostnadsfritjänst utan några villkor. beskriv ditt jobb och få hjälp av kontrollerade företag!"
        }
      ]
    };
  }
})
export default class Home extends Vue {
  urlRoute(category: string) {
    return (
      "/" +
      GetLocale() +
      "/" +
      this.$t(`ROUTES.QUOTATION_REQUEST_CATEGORY.${category}`)
    );
  }
}
