import { HttpClient, HttpClientBlob } from "./HttpClient";
import { AxiosResponse } from "axios";
import { ILoginDetails } from "@/models/LoginDetails";
import { AdminAuthenticationResult } from "@/models/Admin/AdminAuthenticationResult";
import { IAccountRegistration } from "@/models/AccountRegistration";
import { ISubscription } from "@/models/Subscription";
import { IBlogEntry, IBlogEntryUpdate } from "@/models/BlogEntry";
import { IDiscountCodeCreate } from "@/models/DiscountCode";

export class AdminService {
  static async Login(
    loginDetails: ILoginDetails
  ): Promise<AdminAuthenticationResult> {
    const response = await HttpClient.post("/admin/login", loginDetails);
    return response.data as AdminAuthenticationResult;
  }

  static async Logout(): Promise<AxiosResponse> {
    return HttpClient.post("/admin/logout");
  }

  static async MaintainSession(): Promise<AdminAuthenticationResult> {
    const response = await HttpClient.post("/admin/context");
    return response.data as AdminAuthenticationResult;
  }

  static async RegisterCompany(
    accountRegistration: IAccountRegistration
  ): Promise<AxiosResponse> {
    return HttpClient.post("/admin/register-company", accountRegistration);
  }

  static async PendingInvoices(): Promise<AxiosResponse> {
    return HttpClient.get("/admin/invoices/pending-invoices");
  }

  static async DiscountCodes(): Promise<AxiosResponse> {
    return HttpClient.get("/admin/discount-codes/get-all");
  }

  static async CreateDiscountCode(
    discountCode: IDiscountCodeCreate
  ): Promise<AxiosResponse> {
    return HttpClient.post("/admin/discount-codes/create", discountCode);
  }

  static async MarkAsInvoiced(invoiceId: number): Promise<AxiosResponse> {
    return HttpClient.post(`/admin/invoices/invoiced/${invoiceId}`);
  }

  static async GetQuotationRequests(): Promise<AxiosResponse> {
    return HttpClient.get("/admin/quotation-requests");
  }

  static GetQuotationRequest(
    quotationRequestId: number
  ): Promise<AxiosResponse> {
    return HttpClient.get(`/admin/quotation-request/${quotationRequestId}`);
  }

  static async CloseQuotationRequest(
    quotationRequestId: number
  ): Promise<AxiosResponse> {
    return HttpClient.post(
      `/admin/quotation-request/close/${quotationRequestId}`
    );
  }

  static async UpdateZipCode(
    quotationRequestId: number,
    zipCode: string
  ): Promise<AxiosResponse> {
    return HttpClient.patch(
      `/admin/quotation-request/${quotationRequestId}/zipcode/${zipCode}`
    );
  }

  static async DownloadFile(id: number): Promise<AxiosResponse<any>> {
    return HttpClientBlob.get(`admin/file/download/${id}`);
  }

  static async SearchFromCompany(searchQuery: string): Promise<AxiosResponse> {
    return HttpClient.get(`/admin/company/search?query=${searchQuery}`);
  }

  static async SearchForBlog(searchQuery: string): Promise<AxiosResponse> {
    return HttpClient.get(`/admin/blog/search?query=${searchQuery}`);
  }

  static async DeleteBlogEntry(id: number): Promise<AxiosResponse> {
    return HttpClient.post(`/admin/blog/delete?id=${id}`);
  }

  static async GetCompanyDetails(companyId: number): Promise<AxiosResponse> {
    return HttpClient.get(`/admin/company/view/${companyId}`);
  }

  static async UpdateCompanyDetails(
    companyId: number,
    companyName: string,
    businessCode: string,
    contactPerson: string,
    automaticallyRenewSubscription: boolean
  ): Promise<AxiosResponse> {
    return HttpClient.post(`/admin/company/update/${companyId}`, {
      companyName,
      businessCode,
      contactPerson,
      automaticallyRenewSubscription
    });
  }

  static async UpdateUserDetails(
    userId: number,
    email: string,
    mobilePhone: string,
    language: string
  ): Promise<AxiosResponse> {
    return HttpClient.post(`/admin/user/update/${userId}`, {
      email,
      mobilePhone,
      language
    });
  }

  static async BuySubscription(
    companyId: number,
    subscription: ISubscription
  ): Promise<AxiosResponse> {
    return HttpClient.post(
      `/admin/company/buy-subscription/${companyId}`,
      subscription
    );
  }

  static async CreateBlogEntry(blogEntry: IBlogEntry): Promise<AxiosResponse> {
    return HttpClient.post("/admin/blog/create-entry", blogEntry);
  }

  static async UpdateBlogEntry(
    blogEntryUpdate: IBlogEntryUpdate
  ): Promise<AxiosResponse> {
    return HttpClient.post("/admin/blog/update", blogEntryUpdate);
  }
}
