import i18n from "@/i18n";
import QuotationCategory from "@/models/QuotationCategory";

export function GetBrowserLocale(): string {
  const defaultOptions = { countryCodeOnly: true };
  const opt = { ...defaultOptions };
  const navigatorLocale =
    navigator.languages !== undefined
      ? navigator.languages[0]
      : navigator.language;
  if (!navigatorLocale) {
    return "";
  }
  const trimmedLocale = opt.countryCodeOnly
    ? navigatorLocale.trim().split(/-|_/)[0]
    : navigatorLocale.trim();
  return trimmedLocale;
}

export function GetStartingLocale(): string {
  const browserLocale = GetBrowserLocale();
  if (browserLocale == "fi" || browserLocale == "sv" || browserLocale == "en") {
    return browserLocale;
  }
  return process.env.VUE_APP_I18N_FALLBACK_LOCALE || "fi";
}

export function SetLocale(locale: string) {
  i18n.locale = locale;
}

export function GetLocale(): string {
  return i18n.locale;
}

export function GetSupportedLocales(): Array<string> {
  return ["sv", "fi", "en"];
}

export function TranslateCategory(category: QuotationCategory): string {
  return i18n
    .t("QUOTATION_REQUEST_CHILD_CATEGORY." + `${category.name}`)
    .toString();
}
