import axios from "axios";

export const HttpClient = axios.create({
  baseURL: process.env.VUE_APP_BACKEND_URL,
  withCredentials: true
});

export const HttpClientBlob = axios.create({
  baseURL: process.env.VUE_APP_BACKEND_URL,
  withCredentials: true,
  responseType: "blob"
});
