import "./router/component-hooks";

import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import VueGtm from "vue-gtm";
import VueMeta from "vue-meta";

import "@/assets/css/tailwind.css";
import {
  GetLocale,
  GetStartingLocale,
  SetLocale
} from "@/utility/LanguageHelper";
import i18n from "./i18n";
import VueSweetalert2 from "vue-sweetalert2";

import "sweetalert2/dist/sweetalert2.min.css";
import user from "@/store/modules/UserModule";

Vue.config.productionTip = false;

Vue.use(VueSweetalert2);

Vue.use(VueGtm, {
  id: "GTM-N5WHZN6",
  defer: false,
  compatibility: false,
  enabled: true,
  debug: process.env.NODE_ENV === "development",
  loadScript: true,
  trackOnNextTick: false
});

Vue.use(VueMeta, {
  refreshOnceOnNavigation: true
});

new Vue({
  router,
  store,
  render: h => h(App),
  i18n,
  async beforeCreate() {
    const lang = GetLocale();
    if (!["sv", "fi"].includes(lang)) {
      SetLocale(GetStartingLocale());
    }
    await user
      .tryMaintainSession()
      .then(async () => {})
      .catch(() => {});
    if (user.isLoggedIn) {
      SetLocale(user.language);
    }
  }
}).$mount("#app");
