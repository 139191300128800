
import { Vue, Component } from "vue-property-decorator";
import user from "@/store/modules/UserModule";
import Dropdown, { IDropdownItem } from "@/components/Dropdown.vue";
import MobileNavbarDropDown from "@/components/MobileNavbarDropDown.vue";
import Locale from "@/components/Locale.vue";
import { GetLocale } from "@/utility/LanguageHelper";

@Component({
  components: {
    Dropdown,
    Locale,
    MobileNavbarDropDown
  }
})
export default class AppNavbar extends Vue {
  isOpen: boolean = false;
  atTopOfPage: boolean = true;

  get loggedIn(): boolean {
    return user.isLoggedIn;
  }

  get blogUrl() {
    return "/blog/" + GetLocale();
  }

  get dropDownItems(): Array<IDropdownItem> {
    if (this.loggedIn) {
      return [
        { link: "/price-list", title: "GENERAL.PRICE_LIST" },
        {
          link: "/questions-and-answers-companies",
          title: "NAV.FREQUENT_QUESTIONS"
        },
        { link: "/dashboard", title: "DASHBOARD.PROFILE" }
      ];
    } else {
      return [
        { link: "/price-list", title: "GENERAL.PRICE_LIST" },
        {
          link: "/questions-and-answers-companies",
          title: "NAV.FREQUENT_QUESTIONS"
        },
        { link: "/login", title: "GENERAL.LOGIN" },
        { link: "/register", title: "GENERAL.REGISTER_COMPANY" }
      ];
    }
  }

  beforeMount() {
    window.addEventListener("scroll", this.handleScroll);
  }

  handleScroll() {
    if (window.pageYOffset > 0) {
      if (this.atTopOfPage) {
        this.atTopOfPage = false;
      }
    } else {
      if (!this.atTopOfPage) {
        this.atTopOfPage = true;
      }
    }
  }
}
